<template>
  <v-app>
    <!-- AppBar �������� -->
    <AppBar />

    <!-- ������ ������ -->
    <v-main>
      <MainPage />
    </v-main>

    <FooterPage />
  </v-app>
</template>

<script>
import AppBar from "./views/AppBar.vue";
import MainPage from "./views/MainPage.vue";
import FooterPage from "./views/FooterPage.vue";

export default {
  components: {
    AppBar,
    MainPage,
    FooterPage,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap");

html,
body {
  font-family: "Nanum Gothic Coding", monospace;
}

/* ������ ��Ÿ�ϸ� */
.content {
  text-align: center;
  margin-top: 20px;
}
</style>
