<template>
  <v-footer padless>
    <v-row align="center" justify="space-between">
      <!-- ����: �̸��� -->
      <v-col class="text-left" cols="auto"> jh.bae@artechead.com </v-col>

      <!-- ������: Copyright -->
      <v-col class="text-right" cols="auto">
        &copy; 2024 Joonhyung Bae. All rights reserved.
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.v-footer {
  background-color: white;
  color: black;
}
</style>
