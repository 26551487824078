<template>
  <router-link :to="`/post/${slug}`"></router-link>

  <div class="post-item">
    <!-- ������ ���� -->
    <v-row justify="space-between">
      <!-- �� ����: col 1 -->
      <v-col cols="1" class="post-label">
        <span class="label">{{ label }}</span>
      </v-col>

      <!-- ���� ����: col 5 -->
      <v-col cols="5" class="post-titles">
        <div v-for="(title, index) in titles" :key="index" class="title-item">
          <span
            :class="{
              'title-bold post-title': index === 0,
              'title-normal post-title': index !== 0,
            }"
          >
            {{ title }}
          </span>
        </div>
      </v-col>

      <!-- ��ũ ���� -->
      <v-col cols="2" class="post-links">
        <div class="link-item">
          <span>Link - </span>
          <span v-for="(link, index) in links" :key="index">
            <!-- SVG�� ������ -->
            <a :href="link.url" target="_blank" class="link">
              <img :src="getIconPath(link.icon)" alt="icon" class="link-icon" />
              {{ link.name }}
            </a>
            <span v-if="index < links.length - 1">, </span>
          </span>
        </div>
      </v-col>

      <!-- �̹���/���� ����: col 1�� �ݺ� -->
      <v-col cols="3">
        <v-row>
          <!-- ���� �̵�� ������ -->
          <v-col
            cols="12"
            sm="4"
            v-for="(media, index) in mediaItems"
            :key="index"
            class="post-media"
          >
            <div class="media-item" @click="openCarousel(index)">
              <div
                :class="{
                  'media-wrapper': true,
                  'video-wrapper':
                    media.type === 'video' || media.type === 'youtube',
                  'image-wrapper': media.type === 'image',
                }"
              >
                <img
                  v-if="media.type === 'image'"
                  :src="media.src"
                  alt="media"
                  class="image-content"
                />
                <video
                  v-else-if="media.type === 'video'"
                  controls
                  class="video-content"
                >
                  <source :src="media.src" type="video/mp4" />
                </video>
                <iframe
                  v-else-if="media.type === 'youtube'"
                  :src="getYouTubeEmbedUrl(media.src)"
                  class="video-content"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" fullscreen>
      <div class="carousel-overlay" @click="closeCarousel">
        <!-- ĳ���� ������ -->
        <v-card class="carousel-content">
          <v-carousel v-model="currentIndex" hide-delimiters show-arrows>
            <template v-slot:prev="{ props }">
              <v-btn
                class="v-carousel__prev"
                @click.stop="props.onClick"
              ></v-btn>
            </template>

            <template v-slot:next="{ props }">
              <v-btn
                class="v-carousel__next"
                @click.stop="props.onClick"
              ></v-btn>
            </template>

            <!-- ĳ���� �׸� -->
            <v-carousel-item v-for="(media, index) in mediaItems" :key="index">
              <v-col cols="12" class="carousel-media-container">
                <img
                  v-if="media.type === 'image'"
                  :src="media.src"
                  class="carousel-image"
                />
                <video
                  v-else-if="media.type === 'video'"
                  controls
                  class="carousel-video"
                >
                  <source :src="media.src" type="video/mp4" />
                </video>
                <iframe
                  v-else-if="media.type === 'youtube'"
                  :src="getYouTubeEmbedUrl(media.src)"
                  class="carousel-video"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-col>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    titles: {
      type: Array, // ���� �迭 (�ִ� 2��)
      required: true,
    },
    links: {
      type: Array, // ��ũ �迭, �� ��ũ�� { name, url } ����
      default: () => [],
    },
    mediaItems: {
      type: Array, // �̹���/���� �迭, { type: 'image' | 'video' | 'youtube', src } ����
      default: () => [],
    },
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      currentIndex: 0,
    };
  },
  methods: {
    openCarousel(index) {
      this.currentIndex = index;
      this.dialog = true;
    },
    closeCarousel() {
      this.dialog = false;
      this.currentIndex = 0; // ĳ���� ���� �� �ʱ�ȭ
    },
    getYouTubeEmbedUrl(link) {
      const videoIdMatch = link.match(
        /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/))([\w-]{11})/
      );
      return videoIdMatch
        ? `https://www.youtube.com/embed/${videoIdMatch[1]}`
        : null;
    },

    // SVG ������ ��� ��ȯ
    getIconPath(iconName) {
      const icons = {
        "mdi-instagram": require("@/assets/icons/instagram.svg"),
        "mdi-web": require("@/assets/icons/web.svg"),
        "mdi-youtube": require("@/assets/icons/youtube.svg"),
        "mdi-file-document-outline": require("@/assets/icons/file-document-outline.svg"),
        "mdi-google-drive": require("@/assets/icons/google-drive.svg"),
        "mdi-link-variant-off": require("@/assets/icons/link-variant-off.svg"),
      };
      return icons[iconName] || ""; // �������� ������ �� ���ڿ� ��ȯ
    },
  },
  computed: {
    hasPlaceholder() {
      return this.mediaItems.length === 1;
    },
  },
};
</script>

<style>
/* Post Item ��ü ��Ÿ�� */
.post-item {
  width: 100%; /* ȭ�� �ʺ� �� ä��� */
  max-width: 100%;
  padding: 20px 0; /* ���� ���� */
  margin: 0; /* �ܺ� ���� ���� */
  border-top: 1px solid #ccc; /* ���� �� �߰� */
}
/* �� ��Ÿ�� */
.post-label .label {
  font-family: "Nanum Gothic Coding", monospace;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  display: block;
  text-align: center; /* �߾� ���� */
}

/* ����Ͽ��� �� ����� */
@media (max-width: 768px) {
  .post-label {
    display: none; /* �� ����� */
  }
}

.post-titles .title-item {
  font-family: "Nanum Gothic Coding", monospace;
  margin-bottom: 5px;
}

.title-bold {
  font-size: 16px;
  font-weight: bold; /* ù ��° ���� Bold */
}

.title-normal {
  font-size: 16px;
  font-weight: normal; /* �� ��° ������ Normal */
}

/* ��ũ ���� ��Ÿ�� */
.post-links {
  /*margin-left: 20px; /* ��ũ ���� ���� ���� �߰� */
  text-align: center;
}

.post-links .link-item {
  font-size: 14px;
}

.link {
  font-family: "Nanum Gothic Coding", monospace;
  color: black;
  text-decoration: underline; /* ��ũ�� ���� �߰� */
}
/* �̵�� ������ �����̳� */
.post-media {
  flex: 1 1 auto;
  flex-wrap: wrap; /* ��ġ�� �������� �ٹٲ޵ǵ��� ���� */
  justify-content: center; /* ���� ��� ���� */
  align-items: center; /* ���� ��� ���� */
}

/* �̹��� ��Ÿ�� */
.image-content {
  max-width: 100%; /* ȭ�� �ʺ� ���߱� */
  height: auto; /* ���� ���� ���� */
  object-fit: contain; /* �̹����� ������ �Ѿ�� �ʵ��� */
  display: block;
}

.video-content {
  max-width: 100%; /* ȭ�� �ʺ� ���߱� */
  height: auto;
  display: block; /* ���������� ���̵��� ���� */
  object-fit: contain;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  overflow: visible !important; /* ���� ���� */
}

/* ������ �̹��� ��ҿ��� ���� */
.video-content:hover,
.image-content:hover {
  cursor: pointer;
}
/* �̹��� ���� - ���� ���� ���� */
.image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-content {
  max-width: 100%;
  height: auto; /* ���� ���� ���� */
  object-fit: contain; /* �̹����� ������ �Ѿ�� �ʵ��� */
}

.v-carousel {
  padding-top: 0 !important; /* �⺻ ���� ���� */
  height: auto !important; /* ���̸� �ڵ����� ���� */
}

/* ĳ���� �������� ��� */
.carousel-overlay {
  background-color: rgba(0, 0, 0, 0.8); /* ������ ��� */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ĳ���� ������ �߾� ���� */
.carousel-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; /* ȭ�� ��ü ���� ��� */
}

/* ĳ���� �̵�� �����̳� */
.carousel-media-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 90vh; /* ȭ�� ������ 90%�� ���� */
  overflow: hidden; /* ��ġ�� ���� ���� */
}

/* �̹��� ��Ÿ�� */
.carousel-image {
  max-width: 100%; /* �ʺ� �����̳ʿ� ���� */
  max-height: 90vh; /* ȭ�� ������ 90%�� ���� */
  object-fit: contain; /* ���� �����ϸ� ��ü�� ������ */
  display: block;
}

/* ���� ��Ÿ�� */
.carousel-video {
  width: 800px; /* ���� �ʺ� */
  height: 450px; /* ���� ���� */
  object-fit: contain; /* ���� �����ϸ� ������ ���� */
  display: block;
}

/* ĳ���� ����/���� ��ư ��Ÿ�� */
.v-carousel__prev,
.v-carousel__next {
  background-color: transparent !important; /* ������ �����ϰ� */
  color: white !important; /* ȭ��ǥ ���� */
  width: auto; /* ũ�⸦ ���ְ� */
  height: auto; /* �ڵ����� ���� */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none; /* �׸� ��ư �׵θ� ���� */
  box-shadow: none !important; /* �׸��� ���� */
  cursor: pointer; /* Ŭ�� ���� */
}

.v-carousel__prev:hover,
.v-carousel__next:hover {
  background-color: rgba(0, 0, 0, 0.6) !important; /* ȣ�� �� ��� ���� */
  transform: scale(1.1); /* �ణ Ȯ�� */
}

/* ȭ��ǥ ������ */
.v-carousel__prev::before,
.v-carousel__next::before {
  content: ""; /* ȭ��ǥ ���� */
  display: inline-block;
  border: solid black;
  border-width: 0 3px 3px 0;
  padding: 10px; /* ȭ��ǥ ũ�� ���� */
}

.v-carousel__prev::before {
  transform: rotate(135deg); /* ���� ȭ��ǥ */
}

.v-carousel__next::before {
  transform: rotate(-45deg); /* ������ ȭ��ǥ */
}
/* ��ũ ������ ��Ÿ�� */
.link-icon {
  width: 15px;
  height: 15px;
  margin-right: 0px;
  vertical-align: middle;
}
.carousel-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* ĳ���� ���������� �ڿ� ��ġ */
  background: transparent; /* Ŭ�� ������ ���� */
}
.carousel-content {
  z-index: 2; /* ĳ���� �������� ���� ���� ��ġ */
}
.post-media.placeholder .media-item {
  visibility: hidden; /* ������ �ʵ��� ���� */
  height: 0; /* ���̸� ���� */
  padding-top: 100%; /* ������ ���� */
}

.post-media.placeholder {
  display: block;
  flex: 1 1 auto;
}
</style>
