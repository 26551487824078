<template>
  <v-container fluid class="post-container" ref="scrollContainer">
    <!-- 필터 버튼들 -->
    <div class="filter-buttons">
      <v-btn @click="filterPosts('all')" variant="text">All</v-btn>
      <!-- 모바일 전용 추가 버튼 -->
      <template v-if="isMobile">
        <v-btn @click="filterPosts('portfolio')" variant="text"
          >Portfolio</v-btn
        >
        <v-btn @click="filterPosts('cv')" variant="text">CV</v-btn>
        <v-btn @click="openInstagram" variant="text">Instagram</v-btn>
      </template>
      <v-btn @click="filterPosts('art')" variant="text">Art</v-btn>
      <v-btn @click="filterPosts('research')" variant="text">Research</v-btn>
      <v-btn @click="filterPosts('performance')" variant="text"
        >Performance</v-btn
      >
      <v-btn @click="filterPosts('design')" variant="text">Design</v-btn>
      <v-btn @click="filterPosts('talk')" variant="text">Talk</v-btn>
      <v-btn @click="filterPosts('text')" variant="text">Text</v-btn>
      <v-btn @click="filterPosts('interview')" variant="text">Interview</v-btn>
      <v-btn @click="filterPosts('teaching')" variant="text">Teaching</v-btn>
      <v-btn @click="filterPosts('td')" variant="text"
        >Technical Direction</v-btn
      >
      <v-btn @click="filterPosts('article')" variant="text">Article</v-btn>
    </div>
    <v-row no-gutters>
      <v-col cols="12" class="pa-0">
        <div
          v-for="(post, index) in paginatedPosts"
          :key="index"
          class="post-item-container"
        >
          <PostItem
            :label="post.label"
            :titles="post.titles"
            :links="post.links"
            :media-items="post.mediaItems"
            :slug="post.slug"
          />
        </div>
      </v-col>
    </v-row>

    <!-- 페이지네이션 -->
    <div class="pagination-buttons">
      <v-btn
        v-for="page in totalPages"
        :key="page"
        @click="goToPage(page)"
        :class="{ active: currentPage === page }"
        variant="text"
      >
        {{ page }}
      </v-btn>
    </div>

    <!-- 로딩 중 표시 -->
    <v-progress-circular
      v-if="loading"
      indeterminate
      size="64"
      class="loading-spinner"
    />
  </v-container>
</template>

<script>
import PostItem from "@/views/PostItem.vue";

export default {
  props: {
    slug: {
      type: String,
      default: null, // URL에서 전달받은 슬러그
    },
  },
  components: {
    PostItem,
  },
  data() {
    return {
      loading: false,
      posts: [
        {
          label: "Research",
          slug: "2025-research-piano-imputation-poster",
          titles: [
            "2025 (Accepted) | 배준형, 권태균, 김기락, 박다원, 최윤석, 허현, 박재범, 박종화, 남주한. “피아노 연주 손 모션 데이터의 통계적 분석 및 손실 복원을 위한 딥러닝 모델” 한국음악지각인지학회 제 68차 학술대회, Feb. 20, 2025.",
            "",
          ],
          links: [
            {
              name: "",
              icon: "mdi-link-variant-off",
              url: "",
            },
          ],
          mediaItems: [],
        },
        {
          label: "Research",
          slug: "2025-research-immersive-fantasy",
          titles: [
            '2025 (Accepted) | Yerin Doh and Joonhyung Bae, "Immersive Fantasy Based on Digital Nostalgia: Environmental Narratives for Korea’s Millennials and Gen Z," in Proc. Int. Symp. Electron. Art (ISEA), Seoul, South Korea, May 23-29, 2025. (Co-first author)',
            "",
          ],
          links: [
            {
              name: "",
              icon: "mdi-link-variant-off",
              url: "",
            },
          ],
          mediaItems: [],
        },
        {
          label: "Research",
          slug: "2025-research-interactive-cinema-korean-heritage",
          titles: [
            '(Accepted) | Joonhyung Bae, "Interactive Cinema Addressing Korean Digital Heritage Systems," in Proc. Int. Symp. Electron. Art (ISEA), Seoul, South Korea, May 23-29, 2025.',
            "",
          ],
          links: [
            {
              name: "",
              icon: "mdi-link-variant-off",
              url: "",
            },
          ],
          mediaItems: [],
        },
        {
          label: "Talk",
          slug: "2024-talk-undergraduate-graduate-artist",
          titles: [
            "2024 | <As an Undergraduate, as a Graduate, as an Artist> 2024 Art & Design Week, Korea University, Seoul, South Korea (Dec 6, 2024) (Invited Talk)",
            "2024 | <학부에서 대학원에서 작가로서>, 2024 Art & Design Week 2024, 고려대학교, 서울, 대한민국 (Dec 6, 2024)(Invited Talks)",
          ],
          links: [
            {
              name: "JHB",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/p/DDE5wgczcUC/?img_index=1",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/talk/[2024][talk] ku/fhd/0.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/[2024][talk] ku/fhd/1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/[2024][talk] ku/fhd/2.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/[2024][talk] ku/fhd/3.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/[2024][talk] ku/fhd/4.webp"),
            },
          ],
        },
        {
          label: "Interview",
          slug: "2024-interview-yangpyeong-independant",
          titles: [
            "2024 | 2024 Yangpyeong Museum of Art: Conversations with Emerging Artists | New Indépendant: Knowing; From Episteme - Featuring Artist Joonhyung Bae (Nov 29, 2024)",
            "2024 양평군립미술관 신진작가와의 대화 | 뉴 앙데팡당: 앎; 인식으로부터전｜배준형 작가 (Nov 29, 2024)",
          ],
          links: [
            {
              name: "YMuseum",
              icon: "mdi-youtube",
              url: "https://www.youtube.com/watch?v=_8RzyUrQjsc&ab_channel=%EC%96%91%ED%8F%89%EA%B5%B0%EB%A6%BD%EB%AF%B8%EC%88%A0%EA%B4%80",
            },
          ],
          mediaItems: [
            {
              type: "youtube",
              src: "https://www.youtube.com/watch?v=_8RzyUrQjsc&ab_channel=%EC%96%91%ED%8F%89%EA%B5%B0%EB%A6%BD%EB%AF%B8%EC%88%A0%EA%B4%80",
            },
            {
              type: "image",
              src: require("@/assets/interview/[2024][interview] YMuseum/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/interview/[2024][interview] YMuseum/fhd/01.webp"),
            },
          ],
        },
        {
          label: "Talk",
          slug: "2024-talk-warhand-sonata",
          titles: [
            "2024 | “Warhand Sonata: How to Raise a Boring Peace”, Art Korea Lab, Seoul, Korea (Nov 20, 2024)",
            "워핸드 소나타: 시원찮은 평화를 위한 육성법”, 아트코리아랩, 서울, 대한민국 (Nov 20, 2024)",
          ],
          links: [
            {
              name: "Art Korea Lab",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/p/DCVt_bhSwx6/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/talk/[2024][talk] Art Korea Lab/fhd/Presentation1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/[2024][talk] Art Korea Lab/fhd/Presentation2.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2024-art-future-landscape",
          titles: [
            "2024 | 2024 Art Korea Lab Festival <Future Landscape>; Volátus: Temporary Landing, Kirinji Studio 35, Seoul, South Korea (Nov 19-24, 2024)",
            "2024 | 2024 아트코리아랩 페스티벌 <미래풍경>; Volátus: 임시착륙 Temporary Landing, 키린지스튜디오35, 서울, 대한민국 (Nov 19-24, 2024)",
          ],
          links: [
            {
              name: "Media Art Vill",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/p/DCJ02iqhwe8/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Temporary Landing/fhd/Poster1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Temporary Landing/fhd/WarhandSonata_Prototype.webp"),
            },
          ],
        },
        {
          label: "Research",
          slug: "2024-research-flutist-gesture-detection",
          titles: [
            'Jaeran Choi, Taegyun Kwon, Joonhyung Bae, Jiyun Park, Yonghyun Kim, and Juhan Nam, "Real-time Flutist Gesture Cue Detection System for Auto-Accompaniment", in Proc. 25th Int. Soc. Music Inf. Retrieval Conf. (ISMIR), San Francisco, California, USA, Nov. 10-14, 2024.',
            "",
          ],
          links: [
            {
              name: "Paper",
              icon: "mdi-google-drive",
              url: "https://drive.google.com/file/d/1wf9jb7gRIKpsvgVEuOpnT9jkEBQ7cv1C/view?usp=drive_link",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/research/[2024][research] ismir2024/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/research/[2024][research] ismir2024/fhd/01.webp"),
            },
          ],
        },
        {
          label: "Teaching",
          slug: "2024-teaching-swipe-curator",
          titles: [
            "2024 | Artist, Swipe Curator - The Hidden Protagonist of Exhibitions, Daejeon Museum of Art, Daejeon, South Korea (Oct 25, 2024 - Feb 2, 2025)",
            "2024 | 작가, <Swipe Curator - 전시회의 숨은 주인공>, 대전시립미술관, 대전, 대한민국 (Oct 25, 2024-Feb 2, 2025)",
          ],
          links: [
            {
              name: "JHB",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/p/DBlMiflJpX-/",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/teaching/[2024][teaching] Swipe Curator_Prototype/fhd/image01.webp"),
            },
            {
              type: "image",
              src: require("@/assets/teaching/[2024][teaching] Swipe Curator_Prototype/fhd/image02.webp"),
            },
            {
              type: "image",
              src: require("@/assets/teaching/[2024][teaching] Swipe Curator_Prototype/fhd/image03.webp"),
            },
            {
              type: "image",
              src: require("@/assets/teaching/[2024][teaching] Swipe Curator_Prototype/fhd/image04.webp"),
            },
            {
              type: "image",
              src: require("@/assets/teaching/[2024][teaching] Swipe Curator_Prototype/fhd/image05.webp"),
            },
            {
              type: "image",
              src: require("@/assets/teaching/[2024][teaching] Swipe Curator_Prototype/fhd/image06.webp"),
            },
          ],
        },
        {
          label: "Text",
          slug: "2024-text-meta-meta",
          titles: [
            "2024 | Meta-meta, On Meta_ Bae Jun-hyeong, Curator Yoon Jae-young, Yangpyeong Museum of Art, 2024 (Oct, 2024)",
            "Meta-meta, 메타에 대해서_배준형, 윤재영 학예사, 양평군립미술관, 2024 (Oct, 2024)",
          ],
          links: [
            {
              name: "Text",
              icon: "mdi-web",
              url: "https://drive.google.com/file/d/1wQirXQLGM38BwdWIy9Dl4mFDwuonO1Ce/view",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/01.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/02.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/03.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/04.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/05.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/06.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/07.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/08.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/09.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2024-art-new-independant",
          titles: [
            "2024 | New Indépendant: Knowing; From Episteme, Yangpyeong Museum of Art, Yangpyeong, Korea (Oct 16-Nov 20, 2024)",
            "뉴 앙데팡당: 앎; 인식으로부터, 양평군립미술관, 양평, 대한민국 (Oct. 16-Nov. 20, 2024)",
          ],
          links: [
            {
              name: "YMuseum",
              icon: "mdi-web",
              url: "https://www.ymuseum.org/home/sub02.php?mid=16&uid=191",
            },
            {
              name: "YMuseum",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/p/DA5D5Q3Sccy/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/2024-art-new-independant/fhd/Poster.webp"),
            },
            {
              type: "youtube",
              src: "https://www.youtube.com/watch?v=ttJE4X-em3w&ab_channel=JoonhyungBae",
            },
            {
              type: "youtube",
              src: "https://www.youtube.com/watch?v=Wu2ePBZuWvw&ab_channel=JoonhyungBae",
            },
            {
              type: "image",
              src: require("@/assets/art/2024-art-new-independant/fhd/manual.webp"),
            },
          ],
        },
        {
          label: "Design",
          slug: "2024-design-jjilkkum-show",
          titles: [
            "2024 | Jjilkkum Show Poster Design, Dr. Aco, Seoul, Korea (Oct 26, 2024)",
            "2024 | 찔끔쇼 포스터 디자인, Dr. Aco, 서울, 대한민국 (Oct 26, 2024)",
          ],
          links: [
            {
              name: "Youkillbong(육일봉)",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/p/DBHEZ-HSlEC/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/design/[2024][design] DR.ACO/fhd/2024-찔끔쇼 포스터_web.webp"),
            },
          ],
        },

        {
          label: "TD",
          slug: "2024-td-too-obvious-fictional",
          titles: [
            "2024 | <Too Obvious to Be Defined as Fictional>, H.art1, Seoul, Republic of Korea (Aug-Oct, 2024)",
            "2024 | <허구로 명명하기에는 너무도 분명한>, H.art1, 서울, 대한민국 (Aug-Oct, 2024)",
          ],
          links: [
            {
              name: "Dohyeon Lee",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/p/DBlQoBDyqUN/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/TD/[2024][TD] TOBDF/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/TD/[2024][TD] TOBDF/fhd/01.webp"),
            },
          ],
        },
        {
          label: "Interview",
          slug: "2024-interview-seoul-time-travel",
          titles: [
            "2024 | #Seoul Time Travel: Interview with the Artist, Seoul City Hall Seoulim (Oct, 2024)",
            "2024 | #서울시간여행: 작가와의 인터뷰, 서울시청 서울림 (Oct, 2024)",
          ],
          links: [
            {
              name: "Urnban Art Lab in Seoul",
              icon: "mdi-youtube",
              url: "https://www.youtube.com/watch?v=UlnoO-cBcN0&ab_channel=uartlabseoul",
            },
          ],
          mediaItems: [
            {
              type: "youtube",
              src: "https://www.youtube.com/watch?v=UlnoO-cBcN0&ab_channel=uartlabseoul",
            },
          ],
        },

        {
          label: "Art",
          slug: "2024-art-seoul-time-travel",
          titles: [
            "2024 | #Seoul Time Travel, Seoul City Hall Seoulim, Seoul, Korea (Oct. 1-Dec. 31, 2024)",
            "2024 #서울시간여행, 서울시청 서울림, 서울, 대한민국 (Oct. 1-Dec. 31, 2024)",
          ],
          links: [
            {
              name: "Official Website",
              icon: "mdi-web",
              url: "https://www.seoul-lim.kr/time-travel/joonhyung-bae/",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Seoul Time Travel/fhd/poster.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Seoul Time Travel/fhd/img1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Seoul Time Travel/fhd/img2.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2024-art-artplug",
          titles: [
            "2024 | #Seoul Time Travel, Seoul City Hall Seoulim, Seoul, Korea (Oct. 1-Dec. 31, 2024)",
            "2024 | 2024 연수문화재단 문화예술교육 상상오름 예술 놀이터 <아트캠프: 밤샘 미술관 - 디지털 드로잉으로 연수구 문화재/ 멸종위기동물 캐릭터 그리기> 결과 보고, 아트플러그 연수, 인천, 대한민국 (Oct. 1-20, 2024)",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-web",
              url: "https://www.artcamp2024.xyz",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/2024-art-artplug/fhd/0.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2024-art-artplug/fhd/1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2024-art-artplug/fhd/2.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2024-art-artplug/fhd/3.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2024-art-artplug/fhd/4.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2024-art-artplug/fhd/5.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2024-art-artplug/fhd/6.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2024-art-artplug/fhd/7.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2024-art-artplug/fhd/8.webp"),
            },
          ],
        },

        {
          label: "Performance",
          slug: "2024-performance-x-space",
          titles: [
            "2024 | KAIST x Daejeon Arts Center Performance Lab [X-Space], Daejeon Arts Center, Daejeon, Korea (Sep. 28, 2024)",
            "2024 | 카이스트×대전예당 공연실험실 [X-Space], 대전예술의전당, 대전, 대한민국 (Sep. 28, 2024)",
          ],
          links: [
            {
              name: "DAC",
              icon: "mdi-web",
              url: "https://www.daejeon.go.kr/djac/performanceView.do?menuSeq=6709&code=4926&yyyymm=202409&type=image",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/performance/[2024][performance] X-Space/fhd/poster.webp"),
            },
            {
              type: "image",
              src: require("@/assets/performance/[2024][performance] X-Space/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/performance/[2024][performance] X-Space/fhd/01.webp"),
            },
            {
              type: "image",
              src: require("@/assets/performance/[2024][performance] X-Space/fhd/02.webp"),
            },
            {
              type: "image",
              src: require("@/assets/performance/[2024][performance] X-Space/fhd/03.webp"),
            },
          ],
        },
        {
          label: "Teaching",
          slug: "2024-teaching-artcamp2024",
          titles: [
            "2024 | Instructor, <New Indépendant: Knowing; From Episteme> Exhibition-Related Education, Yangpyeong Museum of Art, Yangpyeong, Republic of Korea (Jul. 26-27 2024)",
            "2024 | 강사, 2024 연수문화재단 문화예술교육 상상오름 예술 놀이터 <아트캠프: 밤샘 미술관 - 디지털 드로잉으로 연수구 문화재/ 멸종위기동물 캐릭터 그리기>, 아트플러그 연수, 인천, 대한민국 (Jul. 26-27 2024)",
          ],
          links: [
            {
              name: "Archive",
              icon: "mdi-web",
              url: "https://artcamp2024.xyz",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/teaching/2024-teaching-artcamp2024/fhd/0.webp"),
            },
            {
              type: "image",
              src: require("@/assets/teaching/2024-teaching-artcamp2024/fhd/1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/teaching/2024-teaching-artcamp2024/fhd/2.webp"),
            },
            {
              type: "image",
              src: require("@/assets/teaching/2024-teaching-artcamp2024/fhd/3.webp"),
            },
            {
              type: "image",
              src: require("@/assets/teaching/2024-teaching-artcamp2024/fhd/4.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2024-art-shadows-digital",
          titles: [
            "2024 | Shadows of Digital, Yangpyeong Museum of Art, Yangpyeong, Korea(Jul 19-Aug 18, 2024)",
            "2024 | 디지털의 그림자, 양평군립미술관, 양평, 대한민국(Jul 19-Aug 18, 2024)",
          ],
          links: [
            {
              name: "YMuseum",
              icon: "mdi-web",
              url: "https://www.ymuseum.org/home/sub02.php?mid=16&uid=184",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Shadow of Digital/fhd/poster.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Shadow of Digital/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Shadow of Digital/fhd/01.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Shadow of Digital/fhd/02.webp"),
            },
            {
              type: "youtube",
              src: "https://www.youtube.com/watch?v=kljfqMcPNdY&ab_channel=JoonhyungBae",
            },
          ],
        },
        {
          label: "Talk",
          slug: "2024-talk-dear-passenger-mask",
          titles: [
            "2024 | <Dear Passenger, Please Wear a Mask> - Artist Talk, Faction, Seoul, Republic of Korea (Jul 20, 2024)",
            "2024 | <탑승객님, 마스크를 착용해 주십시오> - 아티스트 토크, 팩션, 서울, 대한민국 (Jul 20, 2024)",
          ],
          links: [
            {
              name: "Faction",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/p/C91khjOJ-XV/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/talk/[2024][talk] Ernst/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/[2024][talk] Ernst/fhd/01.webp"),
            },
          ],
        },

        {
          label: "TD",
          slug: "2024-td-dear-passenger-mask",
          titles: [
            "2024 | <Dear Passenger, Please Wear a Mask>, Faction, Seoul (Apr-July, 2024)",
            "2024 | <탑승객님, 마스크를 착용해 주십시오>, 팩션, 서울 (Apr-July, 2024)",
          ],
          links: [
            {
              name: "Faction",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/p/C9Bt0-uBCyF/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/TD/[2024][TD] Ernst/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/TD/[2024][TD] Ernst/fhd/01.webp"),
            },
            {
              type: "image",
              src: require("@/assets/TD/[2024][TD] Ernst/fhd/02.webp"),
            },
            {
              type: "image",
              src: require("@/assets/TD/[2024][TD] Ernst/fhd/03.webp"),
            },
            {
              type: "image",
              src: require("@/assets/TD/[2024][TD] Ernst/fhd/04.webp"),
            },
            {
              type: "image",
              src: require("@/assets/TD/[2024][TD] Ernst/fhd/05.webp"),
            },
          ],
        },

        {
          label: "Text",
          slug: "2024-text-regional-patrol",
          titles: [
            "2024 | Regional Patrol Series_ Joonhyung Bae, Critic Yu Mi-joo, Art in Culture, July 2024 (Jul, 2024)",
            "지역패트롤시리즈_배준형, 유미주 평론가, 아트인컬처, 2024년 7월호, 2024 (Jul, 2024)",
          ],
          links: [
            {
              name: "Text",
              icon: "mdi-web",
              url: "https://drive.google.com/file/d/1f1nr0sItqE-mOpAnOOeRDyqBCMHGV2U6/view?usp=drive_link",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/text/[2024][text] ArtInCulture_7/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/01.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/02.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/03.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/04.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/05.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/06.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/07.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/08.webp"),
            },
            {
              type: "image",
              src: require("@/assets/text/[2024][text] YMuseum_11/fhd/09.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2024-art-haechi-mediawall",
          titles: [
            "2024 | 2nd Haechimadang Media Wall Exhibition 2024, Haechimadang Media Wall, Seoul, South Korea (Jul. 01-Sep. 30, 2024)",
            "2024 | 2024 해치마당 미디어월 2회 전시, 해치마당 미디어월, 서울, 대한민국 (Jul. 01-Sep. 30, 2024)",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/p/C84Td6IAyO8/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Haechi Madang Media Wall/fhd/00.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2024-art-gbound",
          titles: [
            "2024 | The 1st Gwangju International Youth Art Festival GBOUND, Gwangju Museum, Gwangju, Korea (Jun. 21-27, 2024)",
            "2024 | 제 1회 Gwangju International Youth Art Festival GBOUND, 광주미술관, 광주, 대한민국 (Jun. 21-27, 2024)",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/gbound2024/",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/2024-art-GBOUND/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2024-art-GBOUND/fhd/01.webp"),
            },
          ],
        },
        {
          label: "Talk",
          slug: "2024-talk-arko",
          titles: [
            "2024 | Artists' Perspectives on Technologists, Technologists' Perspectives on Artists, 2nd ARKO Art and Technology Convergence International Conference (Jun. 19, 2024) (Invited Talks)",
            "2024 | “예술가가 바라본 기술가, 기술가가 바라본 예술가”, 제 2회 아르코 예술기술융합 국제 컨퍼런스, 서울, 대한민국 (Jun. 19, 2024) (Invited Talks)",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-web",
              url: "https://www.arko.or.kr/board/view/4053?cid=1808064",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/talk/2024-talk-arko/fhd/0.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/2024-talk-arko/fhd/1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/2024-talk-arko/fhd/2.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/2024-talk-arko/fhd/3.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/2024-talk-arko/fhd/presentation.webp"),
            },
          ],
        },
        {
          label: "Talk",
          slug: "2024-talk-apetalk",
          titles: [
            "2024 | <제 1회 에이프톡>, 에이프톡, 서울, 대한민국 (Mar. 29, 2024) (Invited Talks)",
            "2024 | <The 1st APE Talk>, APE Talk, Republic of Korea (Mar. 29, 2024) (Invited Talks)",
          ],
          links: [
            {
              name: "",
              icon: "mdi-link-variant-off",
              url: "",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/talk/2024-talk-apetalk/fhd/poster.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/2024-talk-apetalk/fhd/announcement.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/2024-talk-apetalk/fhd/talk.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/2024-talk-apetalk/fhd/21.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/2024-talk-apetalk/fhd/full.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2024-art-green-planet",
          titles: [
            "2024 | Cultural Olympics Exhibition Program: The Fabulous Stories to Save the Green Planet, Pyeongchang Daegwallyeong Training Center, Gangwon, Korea (Jan. 10-Feb. 01, 2024)",
            "2024 | 2024 문화올림픽 전시 프로그램 - 지구를 구하는 멋진 이야기들, 평창 대관령 트레이닝 센터, 강원, 대한민국 (Jan. 10-Feb. 01, 2024)",
          ],
          links: [
            {
              name: "Official Website",
              icon: "mdi-web",
              url: "http://2024coep.kr/",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Heritoid/fhd/main_poster.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Heritoid/fhd/YMuseum_00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Heritoid/fhd/01.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Heritoid/fhd/02.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Heritoid/fhd/03.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Heritoid/fhd/04.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2024][art] Heritoid/fhd/05.webp"),
            },
            {
              type: "youtube",
              src: "https://www.youtube.com/watch?v=7LQVkA8n3E4&ab_channel=JoonhyungBae",
            },
          ],
        },

        {
          label: "Teaching",
          slug: "2023-teaching-openlab",
          titles: [
            "2023 | Instructor, Miro Digital Art Open Lab - Micro Lab 3.0, Miro Center, Gwangju, Republic of Korea (Dec.11, 2023)",
            "2023 | 강사, 미로 디지털 아트 오픈랩 - 마이크로 랩 3.0, 미로센터, 광주, 대한민국 (Dec.11, 2023)",
          ],
          links: [
            {
              name: "Archive",
              icon: "mdi-web",
              url: "https://artcamp2024.xyz",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/teaching/2023-teaching-openlab/fhd/0.webp"),
            },
            {
              type: "image",
              src: require("@/assets/teaching/2023-teaching-openlab/fhd/1.webp"),
            },
          ],
        },
        {
          label: "Performance",
          slug: "2023-performance-isaimp2023",
          titles: [
            "2023 | KAIST AI Performance Team; Lecture Concert; Flute Performance Accompanied by AI Pianist with Automatic Cue Detection, International Symposium on AI and Music Performance, Daejeon, Republic of Korea (Dec. 1, 2023)",
            "",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-web",
              url: "https://sites.google.com/view/isaimp2023/home",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/performance/2023-performance-isaimp2023/fhd/20231201_205227.webp"),
            },
            {
              type: "image",
              src: require("@/assets/performance/2023-performance-isaimp2023/fhd/IMG_2329.webp"),
            },
            {
              type: "image",
              src: require("@/assets/performance/2023-performance-isaimp2023/fhd/IMG_6464.webp"),
            },
            {
              type: "image",
              src: require("@/assets/performance/2023-performance-isaimp2023/fhd/IMG_6544.webp"),
            },
          ],
        },
        {
          label: "Design",
          slug: "2023-design-isaimp2023",
          titles: [
            "2023 | <International Symposium on AI and Music Performance> Identity, Poster, Website, Leaflet, Banner, Standing Banner, Name Card, and Meal Ticket, Sumi Jo Performing Arts Research Center Talk Series, Daejeon, Korea (Dec. 1, 2023)",
            "2023 | <International Symposium on AI and Music Performance> 아이덴티티, 포스터, 웹사이트, 리플렛, 현수막, 스탠딩 배너, 이름표, 식권, 조수미공연예술연구센터, 대전, 대한민국 (Dec. 1, 2023)",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-web",
              url: "https://sites.google.com/view/isaimp2023/home",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/design/2023-design-isaimp2023/fhd/poster.webp"),
            },
          ],
        },
        {
          label: "Article",
          slug: "2023-article-cultural-contents-rnd",
          titles: [
            "문화콘텐츠 R&D 전문 인력을 키웁니다 (in Korean)(Nov 29, 2023)",
            "",
          ],
          links: [
            {
              name: "Article",
              icon: "mdi-web",
              url: "https://www.kocca.kr/n_content/vol30/sub15.html",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/article/[2023][article] NContents/fhd/1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/article/[2023][article] NContents/fhd/2.webp"),
            },
            {
              type: "image",
              src: require("@/assets/article/[2023][article] NContents/fhd/3.webp"),
            },
            {
              type: "image",
              src: require("@/assets/article/[2023][article] NContents/fhd/4.webp"),
            },
            {
              type: "image",
              src: require("@/assets/article/[2023][article] NContents/fhd/5.webp"),
            },
          ],
        },
        {
          label: "Talk",
          slug: "2023-talk-snu",
          titles: [
            "2023 | Invited Lecture for the Applied Engineering Design Course (2nd Semester, 2023), Seoul National University, Seoul, Republic of Korea (Mar. 29, 2024) (Invited Talks)",
            "2023 | 2023년 2학기 응용공학 설계 초청 강의, 서울대학교, 서울, 대한민국 (Nov. 27, 2023) (Invited Talks)",
          ],
          links: [
            {
              name: "Syllabus",
              icon: "mdi-web",
              url: "https://speculativeai2023.notion.site/5aaad715830647c695a39be6f4e84136",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/talk/2023-talk-snu/fhd/0.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2023-art-act2023-ctk",
          titles: [
            "2023 | ACT Festival 2023: Future Legends, Asia Culture Center, Gwangju, Korea (Nov. 10-19, 2023)",
            "2023 | ACT 페스티벌 2023 - 미래전설, 국립아시아문화전당, 광주, 대한민국 (Nov. 10-19, 2023)",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-web",
              url: "https://www.acc.go.kr/main/event.do?PID=030606&action=Read&bnkey=EM_0000006805",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/2023-art-act2023-ctk/fhd/0.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2023-art-act2023-ctk/fhd/1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2023-art-act2023-ctk/fhd/2.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2023-art-act2023-ctk/fhd/3.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2023-art-act2023-ctk/fhd/4.webp"),
            },
          ],
        },
        {
          label: "Research",
          slug: "2023-research-piano-data-annotation",
          titles: [
            'Seolhee Lee, Eunjin Choi, Joonhyung Bae, Hyerin Kim, Eita Nakamura, Dasaem Jeong, and Juhan Nam, "Bridging Audio and Symbolic Piano Data Through a Web-Based Annotation Interface", in Proc. 24th Int. Soc. Music Inf. Retrieval Conf. (ISMIR), Milan, Italy, Nov. 6-9, 2023.',
            "",
          ],
          links: [
            {
              name: "Paper",
              icon: "mdi-google-drive",
              url: "https://drive.google.com/file/d/1-zAD7kqdxzVHClVzcsHZRjOxo7Qp7mBB/view?usp=drive_link",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/research/[2023][research] ismir2023/fhd/00.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2023-art-future-lies-ahead",
          titles: [
            "2023 | <FUTURE LIES AHEAD: DAEJEON 1993/2023> - Mobile Game NA:rtmon, Daejeon Museum of Art, Daejeon, Korea (Nov. 07, 2023-Feb. 25, 2024)",
            "2023 | 2023 세계유명미술특별전 <미래저편에: 대전 1993/2023> - 모바일 게임 NA:rtmon, 대전시립미술관, 대전, 대한민국 (Nov. 07, 2023-Feb. 25, 2024)",
          ],
          links: [
            {
              name: "DMA",
              icon: "mdi-web",
              url: "https://www.daejeon.go.kr/dma/DmaExhibView.do?exYear=2023&exType=03&menuSeq=6083&exSeq=105610&&pageIndex=1",
            },
          ],
          mediaItems: [
            {
              type: "youtube",
              src: "https://youtube.com/shorts/uJMHsDVzweg?si=NBqhH_EGgWHRFLXc",
            },

            {
              type: "image",
              src: require("@/assets/art/[2023][art] Nartmon/fhd/01.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2023][art] Nartmon/fhd/02.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2023][art] Nartmon/fhd/03.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2023][art] Nartmon/fhd/04.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2023][art] Nartmon/fhd/05.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/[2023][art] Nartmon/fhd/poster.webp"),
            },
          ],
        },
        {
          label: "Performance",
          slug: "2023-performance-ismir2023",
          titles: [
            "2023 | AI Pianist Performance: Collaboration with Soprano Sumi Jo, 24th, International Society for Music Information Retrieval Conference, Milano, Italy (Nov. 5-9, 2023)",
            "",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-web",
              url: "https://ismir2023program.ismir.net/music_6.html",
            },
          ],
          mediaItems: [
            {
              type: "youtube",
              src: "https://www.youtube.com/watch?v=IKPrdExTjXs&ab_channel=JoonhyungBae",
            },
          ],
        },
        {
          label: "Research",
          slug: "2023-research-sense-convergence",
          titles: [
            'Eugene Hwang, Joonhyung Bae, Wonil Kim, Juhan Nam, and Jeongmi Lee, "Sense of Convergence: Exploring the Artistic Potential of Cross-modal Sensory Transfer in Virtual Reality", in Proc. Int. Symp. Mixed Augmented Reality (ISMAR), Sydney, Australia, Oct. 16-20, 2023.',
            "",
          ],
          links: [
            {
              name: "Paper",
              icon: "mdi-google-drive",
              url: "https://drive.google.com/file/d/1iOrMz7tUvWcMcmOIRVYacy58ezYED3-H/view?usp=drive_link",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/research/[2023][research] ismar2023/fhd/fig1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/research/[2023][research] ismar2023/fhd/fig2.webp"),
            },
            {
              type: "image",
              src: require("@/assets/research/[2023][research] ismar2023/fhd/fig3.webp"),
            },
            {
              type: "image",
              src: require("@/assets/research/[2023][research] ismar2023/fhd/fig4.webp"),
            },
          ],
        },
        {
          label: "Performance",
          slug: "2023-performance-dac",
          titles: [
            "2023 | Daejeon Arts Center 20th Anniversary Concert [Con Amore, With Love], Special Stage at the Outdoor Stair Plaza, Daejeon, Republic of Korea (Oct. 5, 2023)",
            "2023 | 대전예술의전당 개관 20주년 기념음악회 [콘 아모레 con amore, 사랑을 담아], 대전예술의전당 야외 계단광장 특설무대, 대전, 대한민국 (Oct. 5, 2023)",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-web",
              url: "https://www.daejeon.go.kr/djac/performanceView.do?menuSeq=6709&code=4673&yyyymm=202310&type=image",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/performance/2023-performance-dac/fhd/poster.webp"),
            },
            {
              type: "image",
              src: require("@/assets/performance/2023-performance-dac/fhd/image74.webp"),
            },
            {
              type: "youtube",
              src: "https://www.youtube.com/watch?v=EliHqXkb8IQ&t=5s&ab_channel=JoonhyungBae",
            },
          ],
        },
        {
          label: "Talk",
          slug: "2023-talk-yamaha",
          titles: [
            "2023 | KAIST-YAMAHA Joint Workshop; AI for Music Performance, KAIST & YAMAHA, Hamamatsu, Japan (Aug. 24, 2023)",
            "2023 | KAIST-YAMAHA Joint Workshop; AI for Music Performance, KAIST & YAMAHA, 하마마츠, 일본 (Aug. 24, 2023)",
          ],
          links: [
            {
              name: "",
              icon: "mdi-link-variant-off",
              url: "",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/talk/2023-talk-yamaha/fhd/0.webp"),
            },
            {
              type: "image",
              src: require("@/assets/talk/2023-talk-yamaha/fhd/1.webp"),
            },
          ],
        },
        {
          label: "Design",
          slug: "2023-design-nubnubjukjuk",
          titles: [
            "2023 | Nubnubjukjuk A5 Size Art Note, Korea Advanced Institute of Science and Technology, Daejeon, Korea(Jul 27, 2023)",
            "2023 | 넙넙죽죽 A5 아트노트, KAIST, 대전, 대한민국(Jul 27, 2023)",
          ],
          links: [
            {
              name: "KAIST Brand Shop",
              icon: "mdi-web",
              url: "https://brandkaist.com/product/nubnubzukzuk/",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/design/[2023][design] KAIST_Note/fhd/01.webp"),
            },
            {
              type: "image",
              src: require("@/assets/design/[2023][design] KAIST_Note/fhd/02.webp"),
            },
            {
              type: "image",
              src: require("@/assets/design/[2023][design] KAIST_Note/fhd/03.webp"),
            },
            {
              type: "image",
              src: require("@/assets/design/[2023][design] KAIST_Note/fhd/04.webp"),
            },
            {
              type: "image",
              src: require("@/assets/design/[2023][design] KAIST_Note/fhd/05.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2023-art-nubjuk",
          titles: [
            "2023 | Nubzuki's Shinsegae Adventure, Daejeon Art & Science, 7th floor, Daejeon, Korea (Jul. 1-31, 2023)",
            "2023 | 넙죽이의 신세계 대모험, 신세계 Art & Science, 7F 대전홍보관, 대전, 대한민국 (Jul. 1-31, 2023)",
          ],
          links: [
            {
              name: "Article",
              icon: "mdi-web",
              url: "https://news.kaist.ac.kr/news/html/news/?mode=V&mng_no=30150",
            },
            {
              name: "KAM",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/kaist_artmuseum/p/CuHVCBaJjkL/?img_index=1",
            },
          ],
          mediaItems: [
            {
              type: "youtube",
              src: "https://www.youtube.com/watch?v=Xdlz6jP33IM&feature=youtu.be",
            },
            {
              type: "image",
              src: require("@/assets/art/2023-art-nubjuk/fhd/0.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2023-art-nubjuk/fhd/1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2023-art-nubjuk/fhd/2.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2023-art-nubjuk/fhd/3.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2023-art-nubjuk/fhd/4.webp"),
            },
          ],
        },
        {
          label: "Research",
          slug: "2023-research-kinetic-data",
          titles: [
            'Taegyun Kwon, Dawon Park, Joonhyung Bae, Jonghwa Park, Jaebum Park, and Juhan Nam, "Automated Gap-filling Algorithm for Kinetic Data of Finger Movement: Pianist Hand Motion Cleaning Using Spatio-temporal Imputation", in Proc. 29th Congr. Int. Soc. Biomechanics (ISB), Fukuoka, Japan, Jul. 30 - Aug. 3, 2023.',
            "",
          ],
          links: [
            {
              name: "Paper",
              icon: "mdi-google-drive",
              url: "https://drive.google.com/file/d/1dMbKaVXMLA1oNDtJSoyMnD0EQk29PEYN/view?usp=drive_link",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/research/[2023][research] isb2023_2/fhd/00.webp"),
            },
          ],
        },
        {
          label: "Research",
          slug: "2023-research-piano-kinematics",
          titles: [
            'Dawon Park, Taegyun Kwon, Joonhyung Bae, Jonghwa Park, Juhan Nam, and Jaebum Park, "Multivariate Kinematic Analysis of Piano Performance: a Case Study of a Professional Pianist", in Proc. 29th Congr. Int. Soc. Biomechanics (ISB), Fukuoka, Japan, Jul. 30 - Aug. 3, 2023.',
            "",
          ],
          links: [
            {
              name: "Paper",
              icon: "mdi-google-drive",
              url: "https://drive.google.com/file/d/11xTkI1fmLLszd7Jvy1nQdNda-ti1Xy1S/view?usp=drive_link",
            },
          ],
          mediaItems: [],
        },
        {
          label: "Performance",
          slug: "2023-performance-sumiJo",
          titles: [
            "2023 | Innovate Korea 2023, KAIST Lyu Keun-chul Sports Complex Main Arena 1F, Daejeon, Republic of Korea (Jun. 27, 2023)",
            "2023 | 이노베이트코리아 2023, 한국과학기술원 류근철 스포츠컴플렉스 다목적주경기장, 대전, 대한민국 (Jun. 27, 2023)",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-web",
              url: "https://itforum.heraldcorp.com/2023/index.php",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/performance/2023-performance-sumiJo/fhd/image17.webp"),
            },
            {
              type: "image",
              src: require("@/assets/performance/2023-performance-sumiJo/fhd/image19.webp"),
            },
            {
              type: "image",
              src: require("@/assets/performance/2023-performance-sumiJo/fhd/image21.webp"),
            },
            {
              type: "image",
              src: require("@/assets/performance/2023-performance-sumiJo/fhd/image24.webp"),
            },
          ],
        },

        {
          label: "Design",
          slug: "2023-design-audai",
          titles: [
            "2023 | AudAI <Innovate Korea 2023> Credit Page, AudAI, Daejeon, Republic of Korea (Jun. 27, 2023)",
            "2023 | AudAI <이노베이트 코리아 2023> 크레딧 페이지, AudAI, 대전, 대한민국 (Jun. 27, 2023)",
          ],
          links: [
            {
              name: "",
              icon: "mdi-link-variant-off",
              url: "",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/design/2023-design-audai/fhd/image141.webp"),
            },
          ],
        },
        {
          label: "Research",
          slug: "2023-research-thief-of-truth",
          titles: [
            'Joonhyung Bae, "Thief of Truth: VR comics about the relationship between AI and humans", in Proc. 29th Int. Symp. Electronic Art (ISEA), Paris, France, Jul. 16-23, 2023.',
            "",
          ],
          links: [
            {
              name: "Paper",
              icon: "mdi-google-drive",
              url: "https://drive.google.com/file/d/16TW6n0O4Dnuf-JimCIkw07QQoUuJzQnk/view?usp=drive_link",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/research/[2023][research] isea2023/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/research/[2023][research] isea2023/fhd/01.webp"),
            },
            {
              type: "image",
              src: require("@/assets/research/[2023][research] isea2023/fhd/02.webp"),
            },
            {
              type: "image",
              src: require("@/assets/research/[2023][research] isea2023/fhd/03.webp"),
            },
          ],
        },
        {
          label: "Design",
          slug: "2023-design-smjCenter",
          titles: [
            "2023 | Sumi Jo Performing Arts Research Center Talk Series - Prof.Dasaem Jung, Hanoi Hantrakul Seminar Poster, Sumi Jo Performing Arts Research Center Talk Series, Daejeon, Korea (Apr. 24-Aug.11, 2023)",
            "2023 | 조수미공연예술연구센터 토크 시리즈 - Hanoi Hantrakul, 정다샘 교수 포스터, 조수미공연예술연구센터, 대전, 대한민국 (Apr. 24-Aug.11, 2023)",
          ],
          links: [
            {
              name: "Hanoi Hantrakul",
              icon: "mdi-web",
              url: "https://ct.kaist.ac.kr/boards/view/news_board/4089/80/",
            },
            {
              name: "Dasaem Jeong",
              icon: "mdi-web",
              url: "https://ct.kaist.ac.kr/boards/view/news_board/4051/120/",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/design/2023-design-smjCenter/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/design/2023-design-smjCenter/fhd/01.webp"),
            },
          ],
        },
        {
          label: "Research",
          slug: "2023-research-cutting-kim-hcikorea",
          titles: [
            "Joonhyung Bae, Eunjin Choi, Juhan Nam. “Cutting Kim: VR Voice Interaction Game” Proceedings of the HCI Korea, 2023 (Creative Awards Track), Feb. 2-3, 2023.",
            "배준형, 최은진, 남주한. “커팅킴: VR 보이스 인터랙션 게임” 한국HCI학회 학술대회 (Creative Awards Track), Feb. 2-3, 2023.",
          ],
          links: [
            {
              name: "Paper",
              icon: "mdi-google-drive",
              url: "https://drive.google.com/file/d/1uqUakN6RybpJxMcKbFkmfHb-1J2WuAxa/view?usp=drive_link",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/research/[2023][research] HCIKorea2023/fhd/00.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2022-art-infiniteCT",
          titles: [
            "2022 | The Infinite CT, SFactory, Seoul, Korea (Dec. 15-16, 2022)",
            "2022 | The Infinite CT, 무한한 문화기술의 세계로, 에스팩토리, 서울, 대한민국 (Dec. 15-16, 2022)",
          ],
          links: [
            {
              name: "Article",
              icon: "mdi-web",
              url: "https://www.kocca.kr/kocca/koccanews/reportview.do?nttNo=385&menuNo=204767",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/2022-art-infiniteCT/fhd/poster.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-infiniteCT/fhd/image1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-infiniteCT/fhd/image2.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-infiniteCT/fhd/image3.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-infiniteCT/fhd/image4.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-infiniteCT/fhd/image5.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-infiniteCT/fhd/image6.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-infiniteCT/fhd/image7.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-infiniteCT/fhd/image8.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-infiniteCT/fhd/image30.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2022-art-soc-showcase",
          titles: [
            "2022 | Sense of Convergence Showcase, KAIST GSCT XR Studio, Daejeon, Republic of Korea (Nov. 19, 2022)",
            "2022 | Sense of Convergence 쇼케이스, KAIST 문화기술대학원 XR 스튜디오, 대전, 대한민국 (Nov. 19, 2022)",
          ],
          links: [
            {
              name: "JHB",
              icon: "mdi-instagram",
              url: "https://www.instagram.com/p/Ckso1_FPb-c/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/2022-art-soc-showcase/fhd/poster_1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-soc-showcase/fhd/poster_2.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-soc-showcase/fhd/image91.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-soc-showcase/fhd/image93.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-soc-showcase/fhd/image100.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-soc-showcase/fhd/image102.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-soc-showcase/fhd/image103.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-soc-showcase/fhd/image104.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-soc-showcase/fhd/image105.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2022-art-unfoldX-ctm",
          titles: [
            "2022 | The 1st Arts Festival Seoul Unfold X 2022, SFactory, Seoul, Korea (Nov. 7-19, 2022)",
            "2022 | 제 1회 서울융합예술페스티벌 Unfold X 2022, 에스팩토리, 서울, 대한민국 (Nov. 7-19, 2022)",
          ],
          links: [
            {
              name: "UnfoldX Archive",
              icon: "mdi-web",
              url: "https://unfoldx.org/about/history/2022/",
            },
          ],
          mediaItems: [
            {
              type: "youtube",
              src: "https://www.youtube.com/watch?v=VddCqy4tIZ4&ab_channel=%EC%8A%A4%ED%8C%8DTV",
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-unfoldX-ctm/fhd/0.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-unfoldX-ctm/fhd/1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-unfoldX-ctm/fhd/2.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-unfoldX-ctm/fhd/3.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2022-art-thief-of-truth",
          titles: [
            "2022 | Thief of Truth, Move.Mov, Seoul, Korea  (Nov 1-30, 2022)",
            "2022 | 진리를 훔친 자, Move.Mov, 서울, 대한민국 (Nov 1-30, 2022)",
          ],
          links: [
            {
              name: "Official Website",
              icon: "mdi-web",
              url: "https://artson.arko.or.kr/artson/work/view?currentPage=1&searchOperator=or&cid=6180&nid=104&menuID=101&searchMode=&searchValue=%EC%A7%84%EB%A6%AC%EB%A5%BC+%ED%9B%94%EC%B9%9C+%EC%9E%90&sf_chk1=&sf_chk2=&sf_chk3=&sf_chk4=&sf_chk5=&sf_chk6=&sf_tag=&searchField=all&search_btype=104&search_ctype=&sf_stext1=&sf_text5=2022",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/2022-art-thief-of-truth/fhd/05.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-thief-of-truth/fhd/06.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-thief-of-truth/fhd/01.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-thief-of-truth/fhd/02.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-thief-of-truth/fhd/03.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-thief-of-truth/fhd/04.webp"),
            },

            {
              type: "image",
              src: require("@/assets/art/2022-art-thief-of-truth/fhd/06.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-thief-of-truth/fhd/07.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-thief-of-truth/fhd/08.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-thief-of-truth/fhd/09.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-thief-of-truth/fhd/10.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-thief-of-truth/fhd/11.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2022-art-dma-ctk",
          titles: [
            "2022 | Daejeon Art and Science Biennale : The Future City Associated Program - Game and Art: Future City of Fantasy, Daejeon Museum of Art, Daejeon, Korea (Oct. 26-29, 2022)",
            "2022 | 대전과학예술비엔날레: 미래도시연계프로그램- 게임과예술2: 환상의미래도시워크숍, 대전시립미술관, 대전, 대한민국 (Oct. 26-29, 2022)",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-web",
              url: "https://gameandart.cargo.site/",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/01.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/02.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/03.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/04.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/05.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/06.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/07.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/08.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/09.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/10.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/11.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/12.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2022-art-dma-ctk/fhd/13.webp"),
            },
          ],
        },
        {
          label: "Design",
          slug: "2022-design-cuttingkim",
          titles: [
            "2022 | Daejeon Art and Science Biennale: The Future City Associated Program - Game and Art: Future City of Fantasy <Cutting Kim> Poster, Daejeon Museum of Art, Daejeon, Korea (Oct. 26, 2022)",
            "2022 | 대전과학예술비엔날레: 미래도시 연계 프로그램 - 게임과 예술2: 환상의 미래도시 워크숍 <커팅킴> 포스터, 대전시립미술관, 대전, 대한민국 (Oct. 26, 2022)",
          ],
          links: [
            {
              name: "",
              icon: "mdi-link-variant-off",
              url: "",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/design/2022-design-cuttingkim/fhd/poster.webp"),
            },
          ],
        },
        {
          label: "Talk",
          slug: "2022-talk-ctm",
          titles: [
            "2022 | Invited Lecture for the [GCT 742] Game Design Project (2nd Semester, 2022) - <The Making Process of The Classy Trash Monster>, Korea Advanced Institute of Science and Technology, Korea (Sep. 17, 2022) (Invited Talks)",
            "2022 | 2022년 2학기 [GCT 742] 게임 디자인 프로젝트 - <Classy Trash Monster 제작과정>, 한국과학기술원, 대전, 대한민국 (Sep. 17, 2022) (Invited Talks)",
          ],
          links: [
            {
              name: "",
              icon: "mdi-link-variant-off",
              url: "",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/talk/2022-talk-ctm/fhd/0.webp"),
            },
          ],
        },
        {
          label: "Research",
          slug: "2022-research-classy-trash-monster",
          titles: [
            'Joonhyung Bae, Karam Eum, Haram Kwon, Seolhee Lee, Juhan Nam, and Young Yim Doh, "Classy Trash Monster: An Educational Game for Teaching Machine Learning to Non-major Students", in Extended Abstracts CHI Conf. Human Factors Computing Systems, New Orleans, LA, USA, Apr. 30 - May 5, 2022.',
            "",
          ],
          links: [
            {
              name: "Paper",
              icon: "mdi-google-drive",
              url: "https://drive.google.com/file/d/1qIxLvzgjNvwYG-1GYh5wQsjAB2kZ4Qva/view?usp=drive_link",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/research/[2022][research] chi2022/fhd/00.webp"),
            },
          ],
        },
        {
          label: "Design",
          slug: "2022-design-dac",
          titles: [
            "2022 | 2022 Daejeon Arts Center Spring Festival 'Haydn Abend' - Day #2 Beethoven Piano Sonata No.17 in D Minor 'The Tempest', Reproduction of Pianist Kun-Woo Paik's Performance - Video Editing and Proofreading, Daejeon Arts Center, Daejeon, South Korea (Apr. 15, 2022)",
            "2022 | 대전예술의전당 스프링페스티벌 ‘하이든 아벤트’ - Day #2 Beethoven Piano Sonata No.17 in d minor “The Tempest”, 피아니스트 백건우 연주 재현 - 영상 편집 및 교정, 대전예술의전당, 대전, 대한민국 (Apr. 15, 2022)",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-web",
              url: "https://www.daejeon.go.kr/djac/performanceView.do?menuSeq=6709&code=4207&yyyymm=202204&type=image",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/design/2022-design-dac/fhd/image131.webp"),
            },
          ],
        },
        {
          label: "Research",
          slug: "2021-research-mixed-scape",
          titles: [
            'Seungsoon Park, Juhan Nam, Jongpil Lee, Taewan Kim, and Joonhyung Bae, "Mixed Scape: Development of Framework and Artwork for Auditory Correspondence in Mixed Reality", in Proc. Int. Computer Music Conf. (ICMC), Santiago, Chile, Jul. 25-31, 2021.',
            "",
          ],
          links: [
            {
              name: "Paper",
              icon: "mdi-google-drive",
              url: "https://drive.google.com/file/d/1q-WaCKr0pJYPW15_QWT1yDdJuRaTlllp/view?usp=drive_link",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/research/[2021][research] icmc2021/fhd/00.webp"),
            },
            {
              type: "image",
              src: require("@/assets/research/[2021][research] icmc2021/fhd/01.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2020-art-mixedscape",
          titles: [
            "2020 | Mixed Scape, Yeonnamjang, Seoul, Korea (Dec. 17-23, 2020)",
            "2020 | MixedScape, 연남장, 서울, 대한민국 (Dec. 17-23, 2020)",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-web",
              url: "https://neuroscape.ai/MIXED-SCAPE",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/2020-art-mixedscape/fhd/poster.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2020-art-mixedscape/fhd/image1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2020-art-mixedscape/fhd/image3.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2020-art-mixedscape/fhd/image4.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2020-art-mixedscape/fhd/image5.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2020-art-mixedscape/fhd/image6.webp"),
            },
          ],
        },
        {
          label: "Art",
          slug: "2018-art-mmmmm",
          titles: [
            "2018 | Graduation Exhibition of the Department of Fine Arts, Korea University: <Intersection>, Korea University Museum, Seoul, Republic of Korea (Dec. 5–15, 2018)",
            "2018 | 고려대학교 조형미술과 졸업 展 <공집합>, 고려대학교 박물관, 서울, 대한민국 (Dec. 5-15, 2018)",
          ],
          links: [
            {
              name: "Official",
              icon: "mdi-web",
              url: "https://joonhyungbae.portfoliobox.net/",
            },
          ],
          mediaItems: [
            {
              type: "image",
              src: require("@/assets/art/2018-art-mmmmm/fhd/Opening1.webp"),
            },
            {
              type: "image",
              src: require("@/assets/art/2018-art-mmmmm/fhd/Opening2.webp"),
            },
          ],
        },
        {
          label: "Portfolio",
          slug: "portfolio-ko",
          titles: ["포트폴리오(한글)", ""],
          links: [
            {
              name: "Portfolio",
              icon: "mdi-google-drive",
              url: "https://docs.google.com/presentation/d/1XQQPWK-xKvhgAe822GJUsmtqxWrxEB6eDun7oEaClDM/edit#slide=id.p",
            },
          ],
          mediaItems: [],
        },
        {
          label: "Portfolio",
          slug: "portfolio-en",
          titles: ["Portfolio in English", ""],
          links: [
            {
              name: "Portfolio",
              icon: "mdi-google-drive",
              url: "https://docs.google.com/presentation/d/1XQQPWK-xKvhgAe822GJUsmtqxWrxEB6eDun7oEaClDM/edit#slide=id.p",
            },
          ],
          mediaItems: [],
        },
        {
          label: "CV",
          slug: "cv-ko",
          titles: ["이력서(한글)", ""],
          links: [
            {
              name: "Curriculum Vitae",
              icon: "mdi-google-drive",
              url: "https://drive.google.com/file/d/14VY6rPdmwO3khpMwEoHv9ocWMvbftIwf/view?usp=drive_link",
            },
          ],
          mediaItems: [],
        },
        {
          label: "CV",
          slug: "cv-en",
          titles: ["CV in English", ""],
          links: [
            {
              name: "Curriculum Vitae",
              icon: "mdi-google-drive",
              url: "https://drive.google.com/file/d/1G20w3uy5_m-4lXd50oeIwVsMCxjJYycM/view?usp=drive_link",
            },
          ],
          mediaItems: [],
        },
      ],
      filteredPosts: [],
      currentPage: 1, // 현재 페이지
      postsPerPage: 10, // 페이지당 게시물 수
    };
  },
  computed: {
    // 페이지에 표시할 포스트를 계산
    paginatedPosts() {
      const start = (this.currentPage - 1) * this.postsPerPage;
      const end = start + this.postsPerPage;
      return this.filteredPosts.slice(start, end);
    },
    // 총 페이지 수를 계산
    totalPages() {
      return Math.ceil(this.filteredPosts.length / this.postsPerPage);
    },
  },
  methods: {
    goToPage(page) {
      this.currentPage = page;
    },
    filterPosts(filter) {
      if (filter === "all") {
        this.filteredPosts = this.posts;
      } else {
        this.filteredPosts = this.posts.filter(
          (post) => post.label.toLowerCase() === filter
        );
      }
      this.currentPage = 1;
    },
    filterBySlug(slug) {
      const post = this.posts.find((post) => post.slug === slug);
      if (post) {
        this.filteredPosts = [post]; // 해당 포스트만 필터링
        this.currentPage = 1;
      } else {
        console.warn(`Post with slug "${slug}" not found.`);
      }
    },
    openInstagram() {
      window.open("https://www.instagram.com/joonhyung_bae/", "_blank");
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768; // 모바일인지 확인
    },
  },
  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
    this.filterPosts("all"); // 기본 필터

    if (this.slug) {
      this.filterBySlug(this.slug); // URL의 slug로 필터링
    } else {
      this.filterPosts("all");
    }
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  watch: {
    "$route.params.slug"(newSlug) {
      if (newSlug) {
        this.filterBySlug(newSlug); // URL이 변경되면 필터링 업데이트
      }
    },
  },
};
</script>

<style scoped>
/* 스타일 */

.post-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto; /* 100vh 제거 */
  padding-top: 25vh;
  overflow-y: visible; /* 스크롤은 부모(v-main)에 맡김 */
}
.filter-buttons {
  margin-bottom: 20px;
}

.filter-buttons v-btn {
  margin-right: 10px;
}

.pagination-buttons v-btn {
  margin: 5px;
}
.pagination-buttons .active {
  font-weight: bold;
}
/* 모바일 환경에서 post-container의 padding-top을 줄이기 */
@media (max-width: 768px) {
  .post-container {
    padding-top: 15vh;
  }
}
</style>
